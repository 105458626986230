
































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import url from '@/lib/url'
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import VideoForm from './video-form.vue';
import Youtube from '@/components/youtube.vue';

class PageVideoRequest extends PageRequest {
    teacher: number = null;
    specialty: number = null;
    date_from: string = '';
    date_to: string = '';
}

@Component({
    components: { ListView, FormBase, VideoForm, Youtube }
})
export default class Videos extends ListBase {
    entity: string = 'video';
    pagerequest: PageVideoRequest = new PageVideoRequest();
    filterForm: boolean = true;
    youtubeShow: boolean = false;
    url: string = '';

    filterItems = [
        this.gItem({ type: 'text', prop: 'name', label: 'Buscar por Nombre', size: 6, hideDetails: true, onchange: this.afterSave }),
        this.gItem({ type: 'object', prop: 'specialty', label: 'Especialidad', size: 2, generalType: 'SPECIALITY', hideDetails: true, onchange: this.afterSave }),
        this.gItem({ type: 'date', prop: 'date_from', label: 'Videos Desde', size: 2, hideDetails: true }),
        this.gItem({ type: 'date', prop: 'date_to', label: 'Hasta', size: 2, hideDetails: true }),
    ]

    columns = [
        this.gCol('video_date', 'Fecha', 'date'),
        this.gCol('description', 'Curso', 'text'),
        this.gCol('teacher_name', 'Expositor', 'text'),
        this.gColO({ type: 'text', value: 'specialty_id', text: 'Especialidad', generalType: true }),
        this.defaultActions([
            this.getDefActions.edit,
            { name: 'play', color: 'red', title: 'Ver Video', class: 'mr-1', icon: 'smart_display', event: this.showPlay },
        ])
    ];

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    } 

    showPlay(item) {
        this.url = item.url;
        this.youtubeShow = !this.youtubeShow;
    }

    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }

    imageSrc(image) {
        return url + image;
    }

    async created() {
        
    }
}