









import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util'
import url from '@/lib/url'
import Video from '@/store/entities/videos/video';
import ListBase from '@/lib/listbase';
import TeacherForm from '@/views/courses/teacher/teacher-form.vue';

@Component({
    components: { FormBase, ModalView, TeacherForm }, 
    watch: {
        value: function (val) { 
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.video = Util.extend(true, {}, this.$store.state.video.editModel); 
                obj.initForm();
            } else 
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    }
})

export default class VideoForm extends ListBase {
    entity: string = 'video';
    @Prop({ type: Boolean, default: false }) value: boolean;
    video: Video = new Video();
    modalShow: boolean = false;
    uploading: boolean = false;
    modalTeachShow: boolean = false;
    image: any = null;
    modalSetting: any = {
        entity: "video",
        title: 'Video',
        width: '800px',
        showForm: false,
    };
    
    get loading() {
        return this.$store.state[this.entity].loading;
    };

    get isEditing() {
        return this.video.id > 0;
    }
                
    items = [
        this.gItem({ type: 'text', prop: 'code', label: 'Codigo', size: 2, readonly: true }),
        this.gItem({ type: 'object', prop: 'specialty_id', label: 'Especialidad', size: 4, generalType: 'SPECIALITY' }),
        this.gItem({ type: 'date', prop: 'video_date', label: 'Fecha', size: 6 }),
        this.gItem({ type: 'object', prop: 'course_id', label: 'Curso', size: 12, onchange: this.changeCourse }),
        this.gItem({ type: 'text', prop: 'description', label: 'Descripcion', required: true, size: 12 }),
        this.gItem({ type: 'text', prop: 'url', label: 'Url del Video', prefix: 'https://www.youtube.com/watch?v=', required: true, size: 7 }),
        this.gItem({ type: 'active', label: 'Estado', size: 5 }),
        this.gItem({ type: 'object', prop: 'teachers_list', label: 'Expositores', entity: 'teacher', multiple: true, itemText: 'fullname', size: 12, outerIcon: 'add_circle', appendEvent: this.newTeacher }),
    ];

    newTeacher() {
        this.$store.commit('teacher/new');
        this.modalTeachShow = !this.modalTeachShow;
    }

    changeCourse(id) {
        let data = Util.getEntity(this, 'course', id);
        this.video.description = data.name;
    }
    
    initForm() {
        if (!this.isEditing) {

        } else {

        }
    }

    async save() {
        if ((this.$refs.entityForm1 as any).validate()) {
            let model = Util.extend(true, {}, this.video); 

            await this.$store.dispatch({
                type: this.entity + '/' + (this.video.id > 0 ? 'update' : 'create'),
                data: model
            });
            this.$emit('save-success');
            this.cancel();
            if (this.video.id > 0)
                Util.abp.notify.success(this, 'Informacion del Curso actualizada!');
            else
                Util.abp.notify.success(this, 'El Curso ha sido creado!');
        } 
    }        

    cancel() {
        this.$emit('input', false);
        (this.$refs.entityForm1 as any).reset();
    }

    mounted() {       

    }

    created() {
    }
}
