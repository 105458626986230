





import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import FormBase from '@/components/helper/form-base.vue';
import ModalView from '@/components/helper/modal-view.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import Teacher from '@/store/entities/courses/teacher';

@Component({
    components: { FormBase, ModalView },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
            }
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            } else {
                (this as any).initForm();
            }
        }
    }
})

export default class TeacherForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    teacher: Teacher = new Teacher();
    modalSetting: any = {
        entity: "teacher",
        title: 'Ponente',
        width: "800px",
    };

    async getLastCode() { //evento para consultar el ultimo codigo disponible para clientes
        let form = (this as any).$refs.form.$refs.entityForm;
        let code = await this.$store.dispatch({
            type: 'teacher/getLastCode'
        });
        form.model.code = code;
    }

    items = [
        this.gItem({ type: 'text', prop: 'code', label: 'Codigo', required: true, size: 4, readonly: true, icon: 'cached', appendEvent: this.getLastCode, onenter: this.getLastCode }),
        this.gItem({ type: 'object', prop: 'document_type_id', label: 'Tipo de Documento', size: 4 }),
        this.gItem({ type: 'text', prop: 'document', label: 'Documento', size: 4 }),
        this.gItem({ type: 'text', prop: 'firstname', label: 'Nombres', required: true, size: 6 }),
        this.gItem({ type: 'text', prop: 'lastname', label: 'Apellidos', required: true, size: 6 }),
        this.gItem({ type: 'email', prop: 'email', label: 'Email', size: 6 }),
        this.gItem({ type: 'phone', prop: 'phone_mobile', label: 'Numero de Telefono', size: 3 }),
        this.gItem({ type: 'object', prop: 'gender_id', label: 'Genero', size: 3 }),        
        this.gItem({ type: 'active', size: 4 }),
        this.gItem({ type: 'spacer', size: 8 }),
        //this.gItem({ type: 'divider', subheader: 'Accesos' }),
        //this.gItem({ type: 'text', prop: 'username', label: 'Usuario', size: 6 }),
        //this.gItem({ type: 'password', prop: 'password', label: 'Password', size: 6, hint: 'Minimo 8 caracteres', persistentHint: true }),
    ]

    initForm() {
        let $this = (this as any);
        let model = this.$store.state.teacher.editModel;
        if (model.id > 0) {

        } else {
            setTimeout(() => {
                this.getLastCode();
            }, 100);
        }

        //this.$store.commit('address/setList', model.addresses);
        //if ($this.$refs.formAddr != undefined)
        //    $this.$refs.formAddr.initForm();

    }

    async save() {
        this.$emit('save-success');
        this.$emit('input', false);
    }

    cancel() {
        this.$emit('input', false);
    }

    async mounted() {
    }

    async created() {
    }
}
